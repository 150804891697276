// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-templates-hidden-product-tsx": () => import("./../../src/templates/hidden-product.tsx" /* webpackChunkName: "component---src-templates-hidden-product-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-section-tsx": () => import("./../../src/templates/section.tsx" /* webpackChunkName: "component---src-templates-section-tsx" */)
}

